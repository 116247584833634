import * as React from "react";
import {
  Box,
  Skeleton,
  HStack,
  Stack,
  Flex,
  Badge,
  Text,
  SimpleGrid,
  Input,
  InputGroup,
  InputRightElement,
  Center
} from "@chakra-ui/react";
import axios from 'axios';
import { Search2Icon } from "@chakra-ui/icons";
import 'antd/dist/antd.css';
import { Product } from './Product'
import { Copyright } from './Copyright'
import { SocialMediaLinks } from './SocialMediaLinks'
import { useState, useEffect } from "react";
import { Result, Pagination} from 'antd';

export default function Example() {
  const [keywords, getKeywords] = useState([]);
  const [postsDataTokopedia, getpostsDataTokopedia] = useState([]);
  const [postsDataShopee, getpostsDataShopee] = useState([]);
  const [postsDataBlibli, getpostsDataBlibli] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [isTrendingLoaded, setTrendingLoaded] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [errorLoad, setErrorLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const API_URL = 'https://4svz2cevzg.execute-api.ap-southeast-1.amazonaws.com/v1';
  useEffect(() => {
    getTrendingKeywords();
  }, []);
  useEffect(() => {
    if (searchKeyword !== '') {
      getProductDatas(searchKeyword)
    }
  }, [searchKeyword]);
  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top:0 });
  }, [currentPage]);

  const pageChange = (page:number) =>{
    setCurrentPage(page)
  }
  const getProductDatas = (keyword: string) => {
    setLoaded(false);
    axios.post(`${API_URL}/product`, {
      keyword: keyword
    })
      .then((response) => {
        const postDataTokopedia = response.data.tokopedia
        const postDataShopee = response.data.shopee
        const postDataBlibli = response.data.blibli
        getpostsDataTokopedia(postDataTokopedia);
        getpostsDataShopee(postDataShopee);
        getpostsDataBlibli(postDataBlibli);
        setLoaded(true);
        setErrorLoad(false);
        setCurrentPage(1);
      })
      .catch(error => console.error(`Error: ${error}`, setLoaded(true), setErrorLoad(true)));
  }
  const trendingClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    setInputValue((e.target as HTMLInputElement).outerText)
    setSearchKeyword((e.target as HTMLInputElement).outerText)
  }
  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue((e.target as HTMLInputElement).value)
  }
  const inputSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.key === 'Enter') {
      setSearchKeyword((e.target as HTMLInputElement).value)
    }
  }
  const getTrendingKeywords = () => {
    axios.get(`${API_URL}/trending`)
      .then((response) => {
        const trendingKeywords = response.data;
        setInputValue(trendingKeywords[0])
        setSearchKeyword(trendingKeywords[0])
        getKeywords(trendingKeywords);
        setTrendingLoaded(true);
      })
      .catch(error => console.error(`Error: ${error}`));
  }
  return (
    <Box mr="50px" ml="50px">
      <Flex
        bg="white"
        justifyContent="center"
        alignItems="center"
        direction="column"
        boxShadow="0 -1px 6px -1px rgba(0, 0, 0, 0.1)"
        padding={4}
        mt="10px"
      >
        <Flex
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          mt={4}
        >
          <Flex
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Text fontSize="3xl" fontWeight="bold">
              <span role="img" aria-label="PISOW">⚡️</span>PISOW
            </Text>
            <Badge variant="subtle" colorScheme="pink" ml={1}>
              BETA
            </Badge>
          </Flex>
          <Text color="gray.500">
            Cari produk dari banyak marketplace sekaligus
          </Text>
        </Flex>

        <InputGroup maxWidth="1080px" mt="30px">
          <Input
            value={inputValue}
            defaultValue={inputValue}
            onChange={inputChange}
            type="text"
            placeholder="Masukkan kata kunci"
            textAlign="center"
            onKeyUp={inputSubmit}
          />
          <InputRightElement>
            <Search2Icon maxWidth="300px" />
          </InputRightElement>
        </InputGroup>
        <Center>
        <Skeleton isLoaded={isTrendingLoaded}>
          <Stack
            direction={["column", "row"]}
            mt="10px"
            spacing="10px"
            maxWidth="1080px"
          >
            <Badge variant="subtle" colorScheme="green" ml={1}>
              Trending
            </Badge>
            {keywords.length !== 0 ? keywords.map((c, index) => (
              <Text cursor="pointer" fontSize="13px" as="u" onClick={trendingClick} key={index}>{c}</Text>
            )) : ""}
          </Stack>
          </Skeleton>
        </Center>
      </Flex>
      <Center>
        {errorLoad ? <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
        /> : <Skeleton isLoaded={isLoaded}>
          <SimpleGrid
            columns={[1, null, 6]}
            spacingY="10px"
            spacingX="10px"
            mt="10px"
          >
            {postsDataTokopedia.length !== 0 ? postsDataTokopedia.slice((currentPage-1)*6,6*currentPage).map((c, index) => (
              <Product {...c}/>
            )) : ""}
            {postsDataShopee.length !== 0 ? postsDataShopee.slice((currentPage-1)*6,6*currentPage).map((c, index) => (
              <Product {...c}/>
            )) : ""}
            {postsDataBlibli.length !== 0 ? postsDataBlibli.slice((currentPage-1)*6,6*currentPage).map((c, index) => (
              <Product {...c}/>
            )) : ""}
          </SimpleGrid>
        </Skeleton>}

      </Center>
      <Center alignItems="center" alignSelf="center" mt={2} mb={2}>
        <Pagination current={currentPage} onChange={pageChange} total={postsDataTokopedia.length+postsDataShopee.length} showSizeChanger={false} pageSize={12}/>
      </Center>
      <Flex
        bg="white"
        width="100%"
        justifyContent="center"
        alignItems="center"
        direction="column"
        boxShadow="0 -1px 6px -1px rgba(0, 0, 0, 0.1)"
        padding={4}
        mt="10px"
      >
        <HStack spacing={8} mb={2}>
          <Text color="gray.400">Privacy</Text>
          <Text color="gray.400">Terms of Use</Text>
        </HStack>
        <Flex width="100%" justifyContent="center" wrap="wrap">
          <Stack direction="column" spacing="4" align="center" justify="space-between">
            <SocialMediaLinks />
            <Copyright />
          </Stack>

        </Flex>
      </Flex>
    </Box>
  );
}
