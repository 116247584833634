import { ButtonGroup, ButtonGroupProps, IconButton } from '@chakra-ui/react'
import * as React from 'react'
import { FaGithub, FaLinkedin } from 'react-icons/fa'

export const SocialMediaLinks = (props: ButtonGroupProps) => (
  <ButtonGroup variant="ghost" color="gray.600" {...props}>
    <IconButton as="a" href="https://www.linkedin.com/in/cahyamulyadi/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn" icon={<FaLinkedin fontSize="20px" />} />
    <IconButton as="a" href="https://github.com/scz10" target="_blank" rel="noopener noreferrer" aria-label="GitHub" icon={<FaGithub fontSize="20px" />} />
  </ButtonGroup>
)
