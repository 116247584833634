import * as React from "react";
import {
    Box,
    Image,
    Flex,
    Badge,
    Text,
} from "@chakra-ui/react";
import { MdStar } from "react-icons/md";
export interface ProductData {
    name: string;
    url: string;
    image: string;
    originalPrice: string;
    finalPrice: string;
    discountPercentage: number;
    rating: string;
    countReview: number;
    shopLocation: string;
    isOfficial: boolean;
    sold: string;
    source: string;
    productid: number;
}
export const Product = (props: ProductData) => (
    <Box key={props.productid} p="5" maxW="320px" borderWidth="1px">
        <a href={props.url} target="_blank" rel="noopener noreferrer">
            <Image borderRadius="md" src={props.image} />
            <Flex align="baseline" mt={2}>
                {props.isOfficial ? <Badge colorScheme="green">Official Store</Badge> : ""}
                <Text
                    ml={2}
                    textTransform="uppercase"
                    fontSize="sm"
                    fontWeight="bold"
                    color="dark"
                >
                    {props.shopLocation}
                </Text>
            </Flex>
            <Text mt={2} noOfLines={2} fontSize="xl" fontWeight="semibold" lineHeight="short">
                    {props.name}
            </Text>
            <Text color='tomato' fontWeight="bold" fontSize="lg" mt={2}>{props.finalPrice}</Text>
            {props.discountPercentage !== 0 ?
                <Flex align="baseline" >
                    <Badge colorScheme="pink">{props.discountPercentage}%</Badge>
                    <Text as="s" ml={2} fontWeight="bold" fontSize="sm" mt={2}>{props.originalPrice}</Text>
                </Flex>
                : ""
            }

            <Flex mt={2} align="center">
                <Box as={MdStar} color="orange.400" />
                <Text ml={1} fontSize="sm">
                    <b>{!props.rating || props.rating === "0" ? "N/A" : props.rating }</b> ({props.countReview + " Rating"}) | {props.source === "shopee" || props.source === "blibli" ? "Terjual " + props.sold : !props.sold ? "Terjual 0" : props.sold}
                </Text>
            </Flex>
        </a>
    </Box>
)